import React from "react"
import * as Icon from "react-feather"
import CaseDetail from "../../components/Shared/casedetails"
import { graphql } from "gatsby"

const seoTitle = "Benchmarking "
const pageTitle = "Benchmarking"
const subTitle = "Business Process"
const para =
  "Atyeti is managing the process of onboarding these indices onto an Index aggregator platform. The end goal is to automate operations and optimize processes for superior business outcomes."
const blockquote = `"Benchmark - Setting the standard against which the performance of a security, mutual fund, or investment manager can be measured."`

const cardItems = [
  {
    id: 1,
    heading: `Business Challenge`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          This regulatory requirement mandates that fund managers use indices
          created by a 3rd party for this purpose.
        </li>
        <li>
          <Icon.Square />
          Atyeti’s client has an index aggregator platform that displays the
          Index level as well as Security level analytics for both Fixed Income
          and Equity Indices.
        </li>
        <li>
          <Icon.Square />
          This includes indices provided by all the major Index providers like
          S&P, MSCI, FTSE , Russell, Stoxx, Solactive, Bloomberg etc.
        </li>
        <li>
          <Icon.Square />
          The company’s objective of involving the Atyeti team is to improve
          operational performance, faster turnaround, and customer service to
          their clients.
        </li>
        <li>
          <Icon.Square />
          Atyeti is managing the process of onboarding these indices onto an
          Index aggregator platform. The end goal is to automate operations and
          optimize processes for superior business outcomes.
        </li>
      </>
    ),
  },
  {
    id: 2,
    heading: `Solution Provided`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          The Atyeti team creates the feed specifications for calculation of the
          Index level return and analytics, as well as for the Security level
          analytics.
        </li>
        <li>
          <Icon.Square />
          These Feed specifications include creating formulae for calculations
          on the proprietary repository tool of the company, as well as loading
          the historical data for the indices after validation of these
          calculations.
        </li>
        <li>
          <Icon.Square />
          The team is then responsible for making the feeds live, so that the
          further daily Index and constituent files can be processed in an
          automated fashion.{" "}
        </li>
      </>
    ),
  },
  {
    id: 3,
    heading: `Results Achieved`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Atyeti has onboarded and continues to integrate hundreds of indices on
          a monthly basis.
        </li>
        <li>
          <Icon.Square />
          UI: Easy-to-use dashboards which allow users to quickly identify,
          monitor, and manage errors impacting their reports. Allocation using
          rule based auto categorization engine.
        </li>
        <li>
          <Icon.Square />
          The Atyeti team developed the process of creating the feed
          specification of the indices resulting in analytics calculations,
          enhancing the feed spec from index level to index + constituent level
          as well as for loading of the historical data.
        </li>
      </>
    ),
  },
]

const BlogDetails = ({ data }) => {
  return (
    <>
      <CaseDetail
        seoTitle={seoTitle}
        pageTitle={pageTitle}
        cardItems={cardItems}
        subTitle={subTitle}
        para={para}
        blockquote={blockquote}
        images={data}
        serviceName="benchMarking"
      />
    </>
  )
}
export const query = graphql`
  query benchMarkingQuery {
    casedetailsJson(slug: { eq: "benchMarking" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
